import React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { listenToHeightChanges } from 'wix-height-updater';
import { setWidthRestrictions } from '../../../services/resizeWindow';
import { withWixSDKWidget } from '../../../services/withWixSDK';
import { IWixSDKContext, useEnvironment } from '@wix/yoshi-flow-editor';

import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withChallengesListData } from '../../../contexts/ChallengesListDataProvider/withChallengesListData';
import { withSettingsEvents } from '../../../contexts/SettingsEvents/SettingsEvents';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withBI } from '../../../contexts/BI/withBI';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';

import { ChallengesList } from './ChallengesList';
import { EmptyList } from './EmptyList';
import { Spinner } from '../../../components-shared/Spinner';

import { IChallengeListStateProps } from './interfaces';
import { filterChallenges, orderChallenges } from './utils';
import { classes, st } from './Widget.st.css';

export const ChallengeList: React.FC<
  IChallengeListStateProps & IWixSDKContext
> = ({ challengesListData, Wix, editorLoaded }) => {
  const settings = useSettings();
  const { isEditor, isMobile } = useEnvironment();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEditorLoaded, setIsEditorLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    let removeResizeListener: () => void;

    if (isEditor && Wix) {
      removeResizeListener = setWidthRestrictions(Wix);
      listenToHeightChanges(Wix, window);
    }

    setIsEditorLoaded(editorLoaded);

    return () => {
      if (isEditor && Wix) {
        removeResizeListener();
      }
    };
  }, []);

  const filteredChallenges = filterChallenges(
    challengesListData?.memberChallenges || [],
    settings,
  );
  const orderedChallenges = orderChallenges(filteredChallenges, settings);

  if (!orderedChallenges?.length) {
    return <EmptyList />;
  }

  return (
    <div
      data-hook="challenge-list-wrapper"
      className={st(classes.root, { mobile: isMobile })}
    >
      {isLoading && (
        <div className={classes.loader}>
          <Spinner />
        </div>
      )}

      <ChallengesList
        challenges={orderedChallenges}
        isEditorLoaded={isEditorLoaded}
        changeIsLoading={() => {
          setIsLoading(!isLoading);
        }}
      />
    </div>
  );
};

ChallengeList.displayName = 'ChallengesList';

export default applyProviders(ChallengeList, [
  withProviders,
  withLocation,
  withSettingsEvents,
  withBI,
  withGeneralData,
  withChallengesListData,
  withPaidPlans,
  withWixSDKWidget,
]);
