import React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useMemberBI } from '../../../../contexts/BI/BIContext';
import { useMemberPaidPlans } from '../../../../contexts/PaidPlans/paidPlansContext';
import { useSettingsEvents } from '../../../../contexts/SettingsEvents/SettingsEvents';
import {
  Pages,
  useLocation,
} from '../../../../contexts/Location/LocationContext';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import { Challenges } from '../../../../editor/types/Experiments';
import settingsParams from '../../settingsParams';
import { ButtonNames } from '../../../../contexts/BI/interfaces';
import { ILayoutType } from '../../Settings/challengeSettings/challengeSettings.types';

import { getIsFullWidth } from '../../../../selectors/platform';
import { MemberChallenge } from '@wix/ambassador-challenge-service-web/types';

import { ChallengeCard } from '../ChallengeCard';

import { classes, st } from './ChallengesList.st.css';

const getGridStyles = (isMobile: boolean, settings: any) => {
  const layoutType = settings.get(settingsParams.layoutType);

  return layoutType === ILayoutType.Grid
    ? {
        gridTemplateColumns: `repeat(auto-fit, minmax(max(240px, calc(100% / ${
          (isMobile
            ? 1
            : (settings.get(settingsParams.itemsPerRow) as number)) + 1
        })), 1fr))`,
        gridGap: `${settings.get(settingsParams.cardSpacing)}px`,
        justifyItems:
          settings.get(settingsParams.itemsPerRow) === 1 || isMobile
            ? `center`
            : null,
      }
    : {
        gridTemplateColumns: `1fr`,
        gridGap: `${settings.get(settingsParams.sideCardsVerticalSpacing)}px`,
        justifyItems: `center`,
      };
};

export const ChallengesList: React.FC<{
  challenges: MemberChallenge[];
  isEditorLoaded: boolean;
  changeIsLoading(): void;
}> = ({ challenges, isEditorLoaded, changeIsLoading }) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { memberWebAppButtonClick } = useMemberBI();
  const { isMobile, dimensions } = useEnvironment();
  const { goToPage } = useLocation();
  const { userPaidPlans } = useMemberPaidPlans();
  const { buttonState } = useSettingsEvents();

  return (
    <ul
      data-hook="challenge-list"
      className={st(classes.root, { mobile: isMobile })}
      style={getGridStyles(isMobile, settings)}
    >
      {challenges.map((ch, ind) => {
        const challenge = ch?.challenge;

        return (
          <li className={classes.item} key={ind}>
            {challenge ? (
              <ChallengeCard
                memberChallenge={ch}
                isFullWidth={getIsFullWidth(dimensions)}
                goToPage={() => {
                  if (!isEditorLoaded) {
                    return;
                  }

                  const slug = experiments.enabled(Challenges.enableSlugsInUrl)
                    ? challenge?.settings?.seo?.slug
                    : challenge?.id;

                  if (memberWebAppButtonClick) {
                    void memberWebAppButtonClick({
                      buttonName: ButtonNames.ViewChallenge,
                      challengeId: challenge?.id,
                    });
                  }

                  changeIsLoading();

                  goToPage({
                    pageId: Pages.Details,
                    challengeId: slug,
                    reloadPage: true,
                  });
                }}
                userPaidPlans={userPaidPlans}
                buttonState={buttonState}
                even={ind % 2 === 0}
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};
